import * as React from "react";
import { graphql } from "gatsby";
import Faq from "../modules/Faq";

const faqPage = () => <Faq />;

export default faqPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
